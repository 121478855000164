import React from "react";
import { NavLink } from "react-router-dom";

const Faq = () => {
  return (
    <>
      {/* <!-- breadcrumb-area start --> */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">FAQs </h2>
                {/* <!-- breadcrumb-list start --> */}
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-item">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">FAQs </li>
                </ul>
                {/* <!-- breadcrumb-list end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- breadcrumb-area end --> */}

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          {/* <!--====================  Accordion area ====================--> */}
          <div className="accordion-wrapper section-space--ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {/* <!-- section-title-wrap Start --> */}
                  <div className="section-title-wrap text-center section-space--mb_20">
                    <h6 className="section-sub-title mb-20">Style 01</h6>
                    <h3 className="heading">Frequently asked questions</h3>
                  </div>
                  {/* <!-- section-title-wrap Start --> */}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="faq-wrapper  section-space--mt_40">
                    <div id="accordion">
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                            <button
                              className="btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              How can we help your business?
                              <span>
                                {" "}
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-chevron-up"></i>{" "}
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              CoolHax Labs takes into consideration every little
                              detail to make sure the system run smoothly and
                              responsively. CoolHax Labs employs a new technique
                              called Minified Technology for securing customers'
                              database & building up highly confidential
                              firewalls.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                            <button
                              className="btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              What are the advantages of CoolHax Labs?{" "}
                              <span>
                                {" "}
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-chevron-up"></i>{" "}
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              We reduce redundant complex calculations and
                              lengthy erroneous code texts with simpler ones to
                              ensure CoolHax Labs would run seamlessly and the design
                              is reserved in its best form when viewed from a
                              wide range of mobile devices & browsers.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h5 className="mb-0">
                            <button
                              className="collapsed"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              How working process is simplified?
                              <span>
                                {" "}
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-chevron-up"></i>{" "}
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Our service offerings to enhance customer
                              experience throughout the product lifecycle
                              includes – test and repair, service management,
                              and end-to-end warranty management.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  {/* <!-- Start toggles --> */}
                  <div className="toggles-wrapper section-space--mt_40">
                    <div className="faq-wrapper">
                      <div id="faq-toggles">
                        <div className="card">
                          <div className="card-header" id="faq_2">
                            <h5 className="mb-0">
                              <button
                                className="btn-link collapsed"
                                data-toggle="collapse"
                                data-target="#faq_two"
                                aria-expanded="false"
                                aria-controls="faq_two"
                              >
                                What are the advantages of CoolHax Labs?
                                <span>
                                  {" "}
                                  <i className="fas fa-chevron-down"></i>
                                  <i className="fas fa-chevron-up"></i>{" "}
                                </span>
                              </button>
                            </h5>
                          </div>
                          <div
                            id="faq_two"
                            className="collapse"
                            aria-labelledby="faq_2"
                          >
                            <div className="card-body">
                              <p>
                                CoolHax Labs takes into consideration every little
                                detail to make sure the system run smoothly and
                                responsively. CoolHax Labs employs a new technique
                                called Minified Technology for securing
                                customers' database & building up highly
                                confidential firewalls.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faq_3">
                            <h5 className="mb-0">
                              <button
                                className="btn-link collapsed"
                                data-toggle="collapse"
                                data-target="#faq_three"
                                aria-expanded="false"
                                aria-controls="faq_three"
                              >
                                How working process is simplified?{" "}
                                <span>
                                  {" "}
                                  <i className="fas fa-chevron-down"></i>
                                  <i className="fas fa-chevron-up"></i>{" "}
                                </span>
                              </button>
                            </h5>
                          </div>
                          <div
                            id="faq_three"
                            className="collapse"
                            aria-labelledby="faq_3"
                          >
                            <div className="card-body">
                              <p>
                                We reduce redundant complex calculations and
                                lengthy erroneous code texts with simpler ones
                                to ensure CoolHax Labs would run seamlessly and the
                                design is reserved in its best form when viewed
                                from a wide range of mobile devices & browsers.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faq_4">
                            <h5 className="mb-0">
                              <button
                                className="collapsed"
                                data-toggle="collapse"
                                data-target="#faq_four"
                                aria-expanded="false"
                                aria-controls="faq_four"
                              >
                                Product Engineering & Services{" "}
                                <span>
                                  {" "}
                                  <i className="fas fa-chevron-down"></i>
                                  <i className="fas fa-chevron-up"></i>{" "}
                                </span>
                              </button>
                            </h5>
                          </div>
                          <div
                            id="faq_four"
                            className="collapse"
                            aria-labelledby="faq_4"
                            data-parent="#faq-toggles"
                          >
                            <div className="card-body">
                              <p>
                                We are available to freelance hiring with
                                on-demand extra services, including WordPress
                                site design/ redesign, WordPress installation,
                                all-in-one customization, video production,
                                video editing and stop motion video producing.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End toggles --> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!--====================  Accordion area  ====================--> */}

          {/* <!--====================  Accordion area ====================--> */}
          <div className="accordion-wrapper section-space--ptb_100 border-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {/* <!-- section-title-wrap Start --> */}
                  <div className="section-title-wrap text-center section-space--mb_20">
                    <h6 className="section-sub-title mb-20">Style 02</h6>
                    <h3 className="heading">Frequently asked questions</h3>
                  </div>
                  {/* <!-- section-title-wrap Start --> */}
                </div>
              </div>

              <div className="row ">
                <div className="col-lg-6 pr-5">
                  <div className="faq-two-wrapper section-space--mt_40">
                    <div id="accordion_two">
                      <div className="card">
                        <div className="card-header" id="heading__10">
                          <h5 className="mb-0 font-weight--bold">
                            <button
                              className="btn-link"
                              data-toggle="collapse"
                              data-target="#tab__10"
                              aria-expanded="true"
                              aria-controls="tab__10"
                            >
                              How can we help your business?{" "}
                              <span>
                                <i className="far fa-caret-circle-down"></i>
                                <i className="far fa-caret-circle-right"></i>{" "}
                              </span>
                            </button>
                          </h5>
                        </div>

                        <div
                          id="tab__10"
                          className="show"
                          aria-labelledby="heading__10"
                          data-parent="#accordion_two"
                        >
                          <div className="card-body">
                            <p>
                              Through the collaboration with customers in
                              discussing needs and demand, we're able to attain
                              mutual understanding, gain customer trust to offer
                              appropriate advice, and bring about suggestions on
                              suitable technology to transform your business.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="heading__11">
                          <h5 className="mb-0">
                            <button
                              className="btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapse__11"
                              aria-expanded="false"
                              aria-controls="collapse__11"
                            >
                              What are the advantages of CoolHax Labs?
                              <span>
                                <i className="far fa-caret-circle-down"></i>
                                <i className="far fa-caret-circle-right"></i>{" "}
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse__11"
                          className="collapse"
                          aria-labelledby="heading__11"
                          data-parent="#accordion_two"
                        >
                          <div className="card-body">
                            <p>
                              CoolHax Labs takes into consideration every little
                              detail to make sure the system run smoothly and
                              responsively. CoolHax Labs employs a new technique
                              called Minified Technology for securing customers'
                              database & building up highly confidential
                              firewalls.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="heading__12">
                          <h5 className="mb-0">
                            <button
                              className="btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapse__12"
                              aria-expanded="false"
                              aria-controls="collapse__12"
                            >
                              How working process is simplified?{" "}
                              <span>
                                <i className="far fa-caret-circle-down"></i>
                                <i className="far fa-caret-circle-right"></i>{" "}
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse__12"
                          className="collapse"
                          aria-labelledby="heading__12"
                          data-parent="#accordion_two"
                        >
                          <div className="card-body">
                            <p>
                              We reduce redundant complex calculations and
                              lengthy erroneous code texts with simpler ones to
                              ensure CoolHax Labs would run seamlessly and the design
                              is reserved in its best form when viewed from a
                              wide range of mobile devices & browsers.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="heading__13">
                          <h5 className="mb-0">
                            <button
                              className="collapsed"
                              data-toggle="collapse"
                              data-target="#collapse__13"
                              aria-expanded="false"
                              aria-controls="collapse__13"
                            >
                              Product Engineering & Services
                              <span>
                                <i className="far fa-caret-circle-down"></i>
                                <i className="far fa-caret-circle-right"></i>{" "}
                              </span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse__13"
                          className="collapse"
                          aria-labelledby="heading__13"
                          data-parent="#accordion_two"
                        >
                          <div className="card-body">
                            <p>
                              Our service offerings to enhance customer
                              experience throughout the product lifecycle
                              includes – test and repair, service management,
                              and end-to-end warranty management.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  {/* <!-- Start single-popup-wrap --> */}
                  <div className="video-interview section-space--mt_40 video-popup">
                    <a
                      href="https://www.youtube.com/watch?v=9No-FiEInLA"
                      className="video-link mt-30"
                    >
                      <div className="single-popup-wrap">
                        <img
                          className="img-fluid border-radus-5"
                          src="assets/images/bg/faqs-video-poster.jpg"
                          alt="faq video poster"
                        />
                        <div className="ht-popup-video video-button">
                          <div className="video-mark">
                            <div className="wave-pulse wave-pulse-1"></div>
                            <div className="wave-pulse wave-pulse-2"></div>
                          </div>
                          <div className="video-button__two">
                            <div className="video-play">
                              <span className="video-play-icon"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  {/* <!-- End single-popup-wrap --> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!--====================  Accordion area  ====================-->
            <!--====================  Conact us Section Start ====================--> */}
          <div className="contact-us-section-wrappaer infotechno-contact-us-bg section-space--ptb_120">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-lg-6">
                  <div className="conact-us-wrap-one">
                    <h3 className="heading">
                      Obtaining further information by{" "}
                      <span className="text-color-primary">make a contact</span>{" "}
                      with our experienced IT staffs.{" "}
                    </h3>

                    <div className="sub-heading">
                      We’re available for 8 hours a day!
                      <br />
                      Contact to require a detailed analysis and assessment of
                      your plan.
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-lg-6">
                  <div className="contact-info-one text-center">
                    <div className="icon">
                      <span className="fal fa-phone"></span>
                    </div>
                    <h6 className="heading font-weight--reguler">Reach out now!</h6>
                    <h2 className="call-us">
                      <a href="tel:918093962212">8093962212</a>
                    </h2>
                    <div className="contact-us-button mt-20">
                      <a href="#" className="btn btn--secondary">
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--====================  Conact us Section End  ====================--> */}
        </div>
      </div>
    </>
  );
};

export default Faq;
