import React, {useEffect} from "react";
import $ from "jquery";
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/swiper.min.css'

const styles = {
  select: {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    textIndent: '1px',
    textOverflow: 'none'
  }
};


function Home() {

  useEffect(() => {
    const swiper = new Swiper(".hero-slider__container", {
      modules: [Navigation, Pagination, Scrollbar],
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      speed: 150,
      effect: "fade",
      spaceBetween: 0,
      autoplay: {
        delay: 4000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-t01",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 1,
        },

        992: {
          slidesPerView: 1,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });
    $(".hero-slider__container").on('hover',
      function () {
        this.swiper.autoplay.stop();
      },
      function () {
        this.swiper.autoplay.start();
      });

    new Swiper(".brand-logo-slider__container", {
      modules: [Navigation, Pagination, Scrollbar],
      slidesPerView: 6,
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      autoplay: {
        delay: 3000,
      },

      breakpoints: {
        1499: {
          slidesPerView: 6,
        },

        992: {
          slidesPerView: 4,
        },

        768: {
          slidesPerView: 3,
        },

        576: {
          slidesPerView: 2,
        },
      },
    });

    new Swiper(".top-info-slider__container", {
      modules: [Navigation, Pagination, Scrollbar],
      slidesPerView: 3,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      autoplay: true,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-1",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 2,
        },

        992: {
          slidesPerView: 1,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".single-flexible__container", {
      modules: [Navigation, Pagination, Scrollbar],
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-1",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 2,
        },

        992: {
          slidesPerView: 1,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".service-slider__container", {
      modules: [Navigation, Pagination, Scrollbar],
      slidesPerView: 4,
      slidesPerGroup: 4,
      loop: true,
      speed: 1000,
      autoplay: true,
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-service",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1200: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },

        992: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },

        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },

        576: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    });

    new Swiper(".service-slider__project-active", {
      modules: [Navigation, Pagination, Scrollbar],
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      autoplay: false,
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-service",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 1,
        },
        1200: {
          slidesPerView: 1,
        },

        992: {
          slidesPerView: 1,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".three-flexible__container", {
      modules: [Navigation, Pagination, Scrollbar],
      slidesPerView: 3,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      autoplay: true,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-3",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
        },

        992: {
          slidesPerView: 2,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

   new Swiper(".auto--center-flexible__container", {
      slidesPerView: "auto",
      centeredSlides: true,
      freeMode: false,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-auto",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
        },

        992: {
          slidesPerView: 2,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".auto--per-flexible__container", {
      slidesPerView: "auto",
      centeredSlides: false,
      freeMode: true,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-5",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
        },

        992: {
          slidesPerView: 2,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".auto--pree-mode-flexible__container", {
      spaceBetween: 30,
      loop: true,
      freeMode: true,
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination-6",
        type: "bullets",
        clickable: true,
      },
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      speed: 7000,
    });

    new Swiper(".carousel-slider__container", {
      slidesPerView: 3,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-9",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
        },

        992: {
          slidesPerView: 2,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".projects-slider__container", {
      slidesPerView: 3,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-project",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
        },

        1200: {
          slidesPerView: 2,
        },

        992: {
          slidesPerView: 2,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".projects-slider__three", {
      slidesPerView: 3,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 40,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-project",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 3,
        },

        1200: {
          slidesPerView: 2,
        },

        992: {
          slidesPerView: 2,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

     new Swiper(".testimonial-slider__container", {
      slidesPerView: 2,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-t01",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 2,
        },

        992: {
          slidesPerView: 1,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".testimonial-slider__container-two", {
      slidesPerView: 3,
      slidesPerGroup: 1,
      centeredSlides: true,
      loop: true,
      speed: 1000,
      spaceBetween: 50,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-t0",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 2,
        },

        992: {
          slidesPerView: 1,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper(".testimonial-slider-machine", {
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      speed: 1000,
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination-machine",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1499: {
          slidesPerView: 1,
        },

        1200: {
          slidesPerView: 1,
        },

        992: {
          slidesPerView: 1,
        },

        768: {
          slidesPerView: 1,
        },

        576: {
          slidesPerView: 1,
        },
      },
    });
  },[])

  return (
    <div>
      <div id="main-wrapper">
        <div className="site-wrapper-reveal section-space--pt__120">
          {/* <!--============ Service Hero Start ============--> */}
          <div className="service-hero-wrapper service-hero-space service-hero-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                  <div className="service-hero-wrap wow move-up">
                    <div className="service-hero-text text-center">
                      <h3 className="text-white">Transforming Ideas into</h3>
                      <h1 className="font-weight--reguler text-white mb-30">
                      Digital Reality
                      </h1>

                      <div className="service-solution-form-wrap mr-auto ml-auto">
                      <h5 style={{color: "white"}}>Full-service tech solutions for your business growth</h5>
                      </div>
                      <button>
                      Get a Free Consultation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--============ Service Hero End ============-->
        <!--===========  feature-images-wrapper  Start =============--> */}
          <div className="feature-images-wrapper position-relative">
            <div className="grid-wrapper">
              <div className="line line-1"></div>
              <div className="line line-2"></div>
              <div className="line line-3"></div>
              <div className="line line-4"></div>
            </div>
            <div className="swiper-container service-slider__container">
              <div
                className="
                swiper-wrapper
                service-slider__wrapper
                feature-images__three
              "
              >
                <div className="swiper-slide">
                  {/* <!-- ht-box-icon Start --> */}
                  <a href="#" className="ht-box-images style-03 wow move-up">
                    <div className="image-box-wrap">
                      <div className="box-image">
                        <img
                          className="img-fulid"
                          src="assets/images/icons/homescreen/webdev.png"
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <h5 className="heading">Web Development</h5>
                        <div className="text">
                        We craft custom websites that look amazing and work even better, giving your brand the online presence it deserves.
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* <!-- ht-box-icon End --> */}
                </div>
                <div className="swiper-slide">
                  {/* <!-- ht-box-icon Start --> */}
                  <a href="#" className="ht-box-images style-03 wow move-up">
                    <div className="image-box-wrap">
                      <div className="box-image">
                        <img
                          className="img-fulid"
                          src="assets/images/icons/homescreen/mobile.png"
                          sizes="50px"
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <h5 className="heading">Mobile App Development</h5>
                        <div className="text">
                        Let's create a mobile app that your users will actually want to download and use! 
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* <!-- ht-box-icon End --> */}
                </div>
                <div className="swiper-slide">
                  {/* <!-- ht-box-icon Start --> */}
                  <a href="#" className="ht-box-images style-03 wow move-up">
                    <div className="image-box-wrap">
                      <div className="box-image">
                        <img
                          className="img-fulid"
                          src="assets/images/icons/homescreen/pm.png"
                          sizes="50px"
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <h5 className="heading">Product Management</h5>
                        <div className="text">
                        We'll help you turn your product ideas into reality, from the first sketch to the final launch.
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* <!-- ht-box-icon End --> */}
                </div>
                <div className="swiper-slide">
                  {/* <!-- ht-box-icon Start --> */}
                  <a href="#" className="ht-box-images style-03 wow move-up">
                    <div className="image-box-wrap">
                      <div className="box-image">
                        <img
                          className="img-fulid"
                          src="assets/images/icons/homescreen/pjm.png"
                          alt=""
                          sizes="50px"
                        />
                      </div>
                      <div className="content">
                        <h5 className="heading">Project Management</h5>
                        <div className="text">
                        We'll keep your projects running smoothly, so you don't have to stress about deadlines or budgets.
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* <!-- ht-box-icon End --> */}
                </div>
                <div className="swiper-slide">
                  {/* <!-- ht-box-icon Start --> */}
                  <a href="#" className="ht-box-images style-03 wow move-up">
                    <div className="image-box-wrap">
                      <div className="box-image">
                        <img
                          className="img-fulid"
                          src="assets/images/icons/homescreen/consulting.png"
                          alt=""
                        />
                      </div>
                      <div className="content">
                        <h5 className="heading">Tech Consultancy</h5>
                        <div className="text">
                        We'll help you navigate the ever-changing world of technology and find the right solutions for your business. 
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* <!-- ht-box-icon End --> */}
                </div>
                <div className="swiper-slide">
                {/* <!-- ht-box-icon Start --> */}
                <a href="#" className="ht-box-images style-03 wow move-up">
                  <div className="image-box-wrap">
                    <div className="box-image">
                      <img
                        className="img-fulid"
                        src="assets/images/icons/homescreen/analysis.png"
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h5 className="heading">Root Cause Analysis (RCA)</h5>
                      <div className="text">
                      When something goes wrong, we'll dig deep to find out why and make sure it doesn't happen again.
                      </div>
                    </div>
                  </div>
                </a>
                {/* <!-- ht-box-icon End --> */}
              </div>
              <div className="swiper-slide">
                {/* <!-- ht-box-icon Start --> */}
                <a href="#" className="ht-box-images style-03 wow move-up">
                  <div className="image-box-wrap">
                    <div className="box-image">
                      <img
                        className="img-fulid"
                        src="assets/images/icons/homescreen/research.png"
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h5 className="heading">User Research</h5>
                      <div className="text">
                      We'll get inside your users' heads to understand what they really want and need from your products or services.
                      </div>
                    </div>
                  </div>
                </a>
                {/* <!-- ht-box-icon End --> */}
              </div>
              </div>
              <div
                className="
                swiper-pagination swiper-pagination-service
                section-space--mt_30
              "
              ></div>
            </div>
          </div>
          {/* <!--===========  feature-images-wrapper  End =============-->
        <!--===========  Our Company History Start =============--> */}

          <div className="our-company-history section-space--ptb_100">
            <div className="container-fluid">
              <div className="grid-wrapper">
                <div className="line line-1"></div>
                <div className="line line-2"></div>
                <div className="line line-3"></div>
                <div className="line line-4"></div>
              </div>

              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="faq-custom-col">
                    <div className="section-title-wrap">
                      <h6 className="section-sub-title mb-20">Our company</h6>
                      <h3 className="heading">
                      <span className="text-color-primary">
                          {" "}
                          11 years
                        </span>{" "}
                        of Digital Innovation<br />
                        Your Trusted Partner for Success 
                        all these
                        </h3>

                      <p className="text mt-30">
                      For 11 years, CoolHax Labs has been a driving force in digital innovation. We've built a reputation for excellence 
                      by delivering meticulously crafted, high-performance solutions. Our commitment to your success goes beyond code—we 
                      pioneer technologies like Minified Technology to safeguard your data and ensure your peace of mind.
                      </p>

                      <div className="inner-button-box section-space--mt_60">
                        <a href="#" className="ht-btn ht-btn-md">
                          Find out more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="rv-video-section">
                    <div className="ht-banner-01">
                      <img
                        className="
                        img-fluid
                        border-radus-5
                        animation_images
                        one
                        wow
                        fadeInDown
                      "
                        src="assets/images/banners/home-processing-video-intro-slider-slide-01-image-02.jpg"
                        alt=""
                      />
                    </div>

                    <div className="ht-banner-02">
                      <img
                        className="
                        img-fluid
                        border-radus-5
                        animation_images
                        two
                        wow
                        fadeInDown
                      "
                        src="assets/images/banners/home-processing-video-intro-slider-slide-01-image-03.jpg"
                        alt=""
                      />
                    </div>

                    <div className="main-video-box video-popup">
                      <a
                        href="https://www.youtube.com/watch?v=9No-FiEInLA"
                        className="video-link mt-30"
                      >
                        <div className="single-popup-wrap">
                          <img
                            className="img-fluid border-radus-5"
                            src="assets/images/banners/home-processing-video-intro-slider-slide-01-image-01.jpg"
                            alt=""
                          />
                          <div className="ht-popup-video video-button">
                            <div className="video-mark">
                              <div className="wave-pulse wave-pulse-1"></div>
                              <div className="wave-pulse wave-pulse-2"></div>
                            </div>
                            <div className="video-button__two">
                              <div className="video-play">
                                <span className="video-play-icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="ht-banner-03">
                      <img
                        className="
                        img-fluid
                        border-radus-5
                        animation_images
                        three
                        wow
                        fadeInDown
                      "
                        src="assets/images/banners/home-processing-video-intro-slider-slide-01-image-04.jpg"
                        alt=""
                      />
                    </div>

                    <div className="ht-banner-04">
                      <img
                        className="
                        img-fluid
                        border-radus-5
                        animation_images
                        four
                        wow
                        fadeInDown
                      "
                        src="assets/images/banners/home-processing-video-intro-slider-slide-01-image-05.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
        <!--===========  Our Company History Start  End =============-->
        <!--===========  feature-icon-wrapper  Start =============--> */}
          <div className="feature-icon-wrapper bg-gray section-space--ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-wrap text-center section-space--mb_40">
                    <h6 className="section-sub-title mb-20">
                      Industries we Serve
                    </h6>
                    <h3 className="heading">
                      Services We Deliver&nbsp;
                      <br />
                      we provide&nbsp;
                      <span className="text-color-primary">
                        truly prominent IT solutions.
                      </span>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="feature-list__two">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-icon style-02 single-svg-icon-box">
                          <div className="icon-box-wrap">
                            <div className="icon">
                              <div
                                className="svg-icon"
                                id="svg-1"
                                data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                              ></div>
                            </div>
                            <div className="content"> 
                              <h5 className="heading">Web Development</h5>
                              <div className="text">
                              Creating digital experiences that captivate and convert.
                              </div>
                              <div className="feature-btn">
                                <a href="#">
                                  <span className="button-text">
                                    Discover now
                                  </span>
                                  <i className="far fa-long-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-icon style-02 single-svg-icon-box">
                          {/* <!-- ht-box-icon Start --> */}
                          <div className="icon-box-wrap">
                            <div className="icon">
                              <div
                                className="svg-icon"
                                id="svg-5"
                                data-svg-icon="assets/images/svg/linea-ecommerce-money.svg"
                              ></div>
                            </div>
                            <div className="content">
                              <h5 className="heading">Mobile App Development</h5>
                              <div className="text">
                              Your ideas, brought to life in the palm of your hand.
                              </div>
                              <div className="feature-btn">
                                <a href="#">
                                  <span className="button-text">
                                    Discover now
                                  </span>
                                  <i className="far fa-long-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <!-- ht-box-icon End --> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-icon style-02 single-svg-icon-box">
                          {/* <!-- ht-box-icon Start --> */}
                          <div className="icon-box-wrap">
                            <div className="icon">
                              <div
                                className="svg-icon"
                                id="svg-6"
                                data-svg-icon="assets/images/svg/linea-basic-spread-text-bookmark.svg"
                              ></div>
                            </div>
                            <div className="content">
                              <h5 className="heading">Product Management</h5>
                              <div className="text">
                              Guiding your product vision from concept to market success.
                              </div>
                              <div className="feature-btn">
                                <a href="#">
                                  <span className="button-text">
                                    Discover now
                                  </span>
                                  <i className="far fa-long-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <!-- ht-box-icon End --> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-icon style-02 single-svg-icon-box">
                          <div className="icon-box-wrap">
                            <div className="icon">
                              <div
                                className="svg-icon"
                                id="svg-2"
                                data-svg-icon="assets/images/svg/linea-basic-case.svg"
                              ></div>
                            </div>
                            <div className="content">
                              <h5 className="heading">Project Management</h5>
                              <div className="text">
                              Ensuring your projects stay on track, on time, and on budget.
                              </div>
                              <div className="feature-btn">
                                <a href="#">
                                  <span className="button-text">
                                    Discover now
                                  </span>
                                  <i className="far fa-long-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-icon style-02 single-svg-icon-box">
                          {/* <!-- ht-box-icon Start --> */}
                          <div className="icon-box-wrap">
                            <div className="icon">
                              <div
                                className="svg-icon"
                                id="svg-3"
                                data-svg-icon="assets/images/svg/linea-basic-alarm.svg"
                              ></div>
                            </div>
                            <div className="content">
                              <h5 className="heading">Tech Consultancy</h5>
                              <div className="text">
                              Expert advice for navigating the complex world of technology.
                              </div>
                              <div className="feature-btn">
                                <a href="#">
                                  <span className="button-text">
                                    Discover now
                                  </span>
                                  <i className="far fa-long-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <!-- ht-box-icon End --> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 wow move-up">
                        <div className="ht-box-icon style-02 single-svg-icon-box">
                          {/* <!-- ht-box-icon Start --> */}
                          <div className="icon-box-wrap">
                            <div className="icon">
                              <div
                                className="svg-icon"
                                id="svg-4"
                                data-svg-icon="assets/images/svg/linea-basic-geolocalize-05.svg"
                              ></div>
                            </div>
                            <div className="content">
                              <h5 className="heading">User Research</h5>
                              <div className="text">
                              Understand your audience to build products and services they'll love.
                              </div>
                              <div className="feature-btn">
                                <a href="#">
                                  <span className="button-text">
                                    Discover now
                                  </span>
                                  <i className="far fa-long-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <!-- ht-box-icon End --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="feature-list-button-box mt-30 text-center">
                    <a href="#" className="ht-btn ht-btn-md">
                      Talk to a consultant
                    </a>
                    <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                      Contact us now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--===========  feature-icon-wrapper  End =============-->
        <!--====================  gradation process area ====================--> */}
          <div className="gradation-process-area section-space--ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="gradation-title-wrapper section-space--mb_60">
                    <div className="gradation-title-wrap">
                      <h6 className="section-sub-title text-black mb-20">
                        How we works
                      </h6>
                      <h4 className="heading">
                        How it helps&nbsp;
                        <span className="text-color-primary">
                          your <br />
                          business succeed
                        </span>
                      </h4>
                    </div>

                    <div className="gradation-sub-heading">
                      <h3 className="heading">
                        <mark>04</mark> Steps
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="ht-gradation style-01">
                    {/* <!-- Single item gradation Start --> */}
                    <div
                      className="item item-1 animate wow fadeInRight"
                      data-wow-delay="0.1s"
                    >
                      <div className="line"></div>
                      <div className="circle-wrap">
                        <div className="mask">
                          <div className="wave-pulse wave-pulse-1"></div>
                          <div className="wave-pulse wave-pulse-2"></div>
                          <div className="wave-pulse wave-pulse-3"></div>
                        </div>

                        <h6 className="circle">1</h6>
                      </div>

                      <div className="content-wrap">
                        <h6 className="heading">Identify</h6>

                        <div className="text">
                        Uncover the issue and define its impact.
                        <br/>
                        What's broken, and how bad is it?
                        </div>

                        <a className="gradation-btn" href="#">
                          <span className="button-text" data-text="Look more">
                            Look more
                          </span>
                          <span className="button-icon far fa-long-arrow-right"></span>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Single item gradation End -->
                  <!-- Single item gradation Start --> */}
                    <div
                      className="item item-1 animate wow fadeInRight"
                      data-wow-delay="0.15s"
                    >
                      <div className="line"></div>
                      <div className="circle-wrap">
                        <div className="mask">
                          <div className="wave-pulse wave-pulse-1"></div>
                          <div className="wave-pulse wave-pulse-2"></div>
                          <div className="wave-pulse wave-pulse-3"></div>
                        </div>

                        <h6 className="circle">2</h6>
                      </div>

                      <div className="content-wrap">
                        <h6 className="heading">
                        Investigate
                        </h6>

                        <div className="text">
                        Dig deep to understand the root cause.
                        <br/>
                        Why did this happen in the first place?
                        </div>

                        <a className="gradation-btn" href="#">
                          <span className="button-text" data-text="Look more">
                            Look more
                          </span>
                          <span className="button-icon far fa-long-arrow-right"></span>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Single item gradation End -->
                  <!-- Single item gradation Start --> */}
                    <div
                      className="item item-1 animate wow fadeInRight"
                      data-wow-delay="0.20s"
                    >
                      <div className="line"></div>
                      <div className="circle-wrap">
                        <div className="mask">
                          <div className="wave-pulse wave-pulse-1"></div>
                          <div className="wave-pulse wave-pulse-2"></div>
                          <div className="wave-pulse wave-pulse-3"></div>
                        </div>

                        <h6 className="circle">3</h6>
                      </div>

                      <div className="content-wrap">
                        <h6 className="heading">Ideate</h6>

                        <div className="text">
                        Brainstorm potential solutions, big and small.
                        <br/>
                        Let's get creative and explore all options
                        </div>

                        <a className="gradation-btn" href="#">
                          <span className="button-text" data-text="Look more">
                            Look more
                          </span>
                          <span className="button-icon far fa-long-arrow-right"></span>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Single item gradation End -->
                  <!-- Single item gradation Start --> */}
                    <div
                      className="item item-1 animate wow fadeInRight"
                      data-wow-delay="0.25s"
                    >
                      <div className="line"></div>
                      <div className="circle-wrap">
                        <div className="mask">
                          <div className="wave-pulse wave-pulse-1"></div>
                          <div className="wave-pulse wave-pulse-2"></div>
                          <div className="wave-pulse wave-pulse-3"></div>
                        </div>

                        <h6 className="circle">4</h6>
                      </div>

                      <div className="content-wrap">
                        <h6 className="heading">Evaluate</h6>

                        <div className="text">
                        Analyze each solution's feasibility and effectiveness.
                        <br/>
                        Which fix offers the best results with the least risk?
                        </div>

                        <a className="gradation-btn" href="#">
                          <span className="button-text" data-text="Look more">
                            Look more
                          </span>
                          <span className="button-icon far fa-long-arrow-right"></span>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Single item gradation End --> */}
                    <div
                      className="item item-1 animate wow fadeInRight"
                      data-wow-delay="0.25s"
                    >
                      <div className="line"></div>
                      <div className="circle-wrap">
                        <div className="mask">
                          <div className="wave-pulse wave-pulse-1"></div>
                          <div className="wave-pulse wave-pulse-2"></div>
                          <div className="wave-pulse wave-pulse-3"></div>
                        </div>

                        <h6 className="circle">5</h6>
                      </div>

                      <div className="content-wrap">
                        <h6 className="heading">Implement</h6>

                        <div className="text">
                        Put the chosen solution into action with a clear plan.
                        <br/>
                        Let's get this done right – time for action
                        </div>

                        <a className="gradation-btn" href="#">
                          <span className="button-text" data-text="Look more">
                            Look more
                          </span>
                          <span className="button-icon far fa-long-arrow-right"></span>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Single item gradation End --> */}
                    <div
                      className="item item-1 animate wow fadeInRight"
                      data-wow-delay="0.25s"
                    >
                      <div className="line"></div>
                      <div className="circle-wrap">
                        <div className="mask">
                          <div className="wave-pulse wave-pulse-1"></div>
                          <div className="wave-pulse wave-pulse-2"></div>
                          <div className="wave-pulse wave-pulse-3"></div>
                        </div>

                        <h6 className="circle">6</h6>
                      </div>

                      <div className="content-wrap">
                        <h6 className="heading">Monitor</h6>

                        <div className="text">
                        Track results and make adjustments as needed.
                        <br/>
                        Keep an eye on things to ensure lasting success.
                        </div>

                        <a className="gradation-btn" href="#">
                          <span className="button-text" data-text="Look more">
                            Look more
                          </span>
                          <span className="button-icon far fa-long-arrow-right"></span>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Single item gradation End --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--====================  End of gradation process area  ====================-->
        <!--========== Call to Action Area Start ============--> */}
          <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-8 col-lg-7">
                  <div className="cta-content md-text-center">
                    <h3 className="heading text-white">
                      We run all kinds of Tech services that vow your
                      <span className="text-color-secondary"> success</span>
                    </h3>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5">
                  <div className="cta-button-group--one text-center">
                    <a href="#" className="btn btn--white btn-one">
                      <span className="btn-icon mr-2">
                        <i className="far fa-comment-alt-dots"></i>
                      </span>
                      Let's talk
                    </a>
                    <a href="#" className="btn btn--secondary btn-two">
                      <span className="btn-icon mr-2">
                        <i className="far fa-info-circle"></i>
                      </span>
                      Get info
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--========== Call to Action Area End ============-->
        <!--=========== Service Projects Wrapper Start =============--> */}
          <div className="service-projects-wrapper section-space--pt_100 mb-20">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-wrap text-center section-space--mb_60">
                    <h6 className="section-sub-title mb-20">Case studies</h6>
                    <h3 className="heading">
                      Proud projects that&nbsp;
                      <span className="text-color-primary">
                        make us stand out
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="swiper-container service-slider__project-active">
                <div className="swiper-wrapper service-slider__project">
                  <div className="swiper-slide">
                    <div className="row">
                      <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                        <div className="service-project-slide-info">
                          <h4 className="heading font-weight--reguler mb-10">
                            Aqua Technology Case Studies
                          </h4>
                          <p className="sub-text text-color-primary">
                            Cyber Security
                          </p>
                          <div className="text">
                            ARM Holdings is the world's leading semiconductor
                            Intellectual Property (IP) supplier. A semiconductor
                            is the electronic controller at the heart of many
                            devices that we use every day.
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                        <div className="slide-image">
                          <div className="image-wrap">
                            <div className="image">
                              <img
                                className="img-fluid"
                                src="assets/images/features/home-services-case-study-slide-image-01.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="row">
                      <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                        <div className="service-project-slide-info">
                          <h4 className="heading font-weight--reguler mb-10">
                            Aqua Technology Case Studies
                          </h4>
                          <p className="sub-text text-color-primary">
                            Cyber Security
                          </p>
                          <div className="text">
                            ARM Holdings is the world's leading semiconductor
                            Intellectual Property (IP) supplier. A semiconductor
                            is the electronic controller at the heart of many
                            devices that we use every day.
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                        <div className="slide-image">
                          <div className="image-wrap">
                            <div className="image">
                              <img
                                className="img-fluid"
                                src="assets/images/features/home-services-case-study-slide-image-02.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--=========== Service Projects Wrapper End =============-->
        <!--=========== Service Projects Wrapper Start =============--> */}
          <div className="service-projects-wrapper section-space--ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title-wrap text-center section-space--mb_60">
                    <h6 className="section-sub-title mb-20">Latest Blogs</h6>
                    <h3 className="heading">
                      Latest news are&nbsp;
                      <span className="text-color-primary">
                        on top all times
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="latest-news-wrap">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="single-blog-lg-item wow move-up">
                      {/* <!-- Post Feature Start --> */}
                      <a href="blog-post-layout-one.html">
                        <div className="post-blog-thumbnail">
                          <img
                            className="img-fluid"
                            src="assets/images/blog/blog-03-540x330.jpg"
                            alt="Blog Images"
                          />
                          <div className="post-meta mt-20">
                            <div className="post-author">
                              <img
                                className="img-fluid avatar-96"
                                src="assets/images/team/admin.jpeg"
                                alt=""
                              />
                              Harry Ferguson
                            </div>
                            <div className="post-date">
                              <span className="far fa-calendar meta-icon"></span>
                              January 18, 2019
                            </div>
                          </div>
                        </div>
                      </a>
                      {/* <!-- Post Feature End -->
                    <!-- Post info Start --> */}
                      <div className="post-info lg-blog-post-info mt-20">
                        <h4 className="post-title">
                          <a href="blog-post-layout-one.html">
                            5 Ways Technology Has Improved Business Today
                          </a>
                        </h4>
                        <div className="post-excerpt mt-15">
                          <p>
                            They play a role in making operations more seamless,
                            bridging the gap between authorities, consumers and
                            businesses. …
                          </p>
                        </div>
                        <div className="btn-text mt-15">
                          <a href="#">
                            Read more
                            <i className="ml-1 button-icon far fa-long-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                      {/* <!-- Post info End --> */}
                    </div>
                  </div>
                  <div className="col-lg-6 blog-list-service">
                    <div className="single-blog-lg-list wow move-up">
                      {/* <!-- Post Feature Start --> */}
                      <a href="blog-post-layout-one.html">
                        <div className="post-blog-thumbnail">
                          <img
                            className="img-fluid"
                            src="assets/images/blog/blog-01-480x312.jpg"
                            alt="Blog Images"
                          />
                          <div className="post-meta mt-20">
                            <div className="post-author">
                              <img
                                className="img-fluid avatar-96"
                                src="assets/images/team/admin.jpeg"
                                alt=""
                              />
                              Harry Ferguson
                            </div>
                          </div>
                        </div>
                      </a>
                      {/* <!-- Post Feature End -->
                    <!-- Post info Start --> */}
                      <div className="post-info lg-blog-post-info">
                        <div className="post-meta mb-10">
                          <div className="post-date">
                            <span className="far fa-calendar meta-icon"></span>
                            January 18, 2019
                          </div>
                        </div>
                        <h5 className="post-title">
                          <a href="blog-post-layout-one.html">
                            Ideas for High Returns on Investment
                          </a>
                        </h5>
                      </div>
                      {/* <!-- Post info End --> */}
                    </div>
                    <div className="single-blog-lg-list wow move-up">
                      {/* <!-- Post Feature Start --> */}
                      <a href="blog-post-layout-one.html">
                        <div className="post-blog-thumbnail">
                          <img
                            className="img-fluid"
                            src="assets/images/blog/blog-08-480x312.jpg"
                            alt="Blog Images"
                          />
                          <div className="post-meta mt-20">
                            <div className="post-author">
                              <img
                                className="img-fluid avatar-96"
                                src="assets/images/team/admin-02.jpeg"
                                alt=""
                              />
                              Harry Ferguson
                            </div>
                          </div>
                        </div>
                      </a>
                      {/* <!-- Post Feature End -->
                    <!-- Post info Start --> */}
                      <div className="post-info lg-blog-post-info">
                        <div className="post-meta mb-10">
                          <div className="post-date">
                            <span className="far fa-calendar meta-icon"></span>
                            February 21, 2019
                          </div>
                        </div>
                        <h5 className="post-title">
                          <a href="blog-post-layout-one.html">
                            How Technology Made Businesses More Efficient
                          </a>
                        </h5>
                      </div>
                      {/* <!-- Post info End --> */}
                    </div>
                    <div className="single-blog-lg-list wow move-up">
                      {/* <!-- Post Feature Start --> */}
                      <a href="blog-post-layout-one.html">
                        <div className="post-blog-thumbnail">
                          <img
                            className="img-fluid"
                            src="assets/images/blog/blog-07-480x312.jpg"
                            alt="Blog Images"
                          />
                          <div className="post-meta mt-20">
                            <div className="post-author">
                              <img
                                className="img-fluid avatar-96"
                                src="assets/images/team/admin-02.jpeg"
                                alt=""
                              />
                              Harry Ferguson
                            </div>
                          </div>
                        </div>
                      </a>
                      {/* <!-- Post Feature End -->
                    <!-- Post info Start --> */}
                      <div className="post-info lg-blog-post-info">
                        <div className="post-meta mb-10">
                          <div className="post-date">
                            <span className="far fa-calendar meta-icon"></span>
                            January 18, 2019
                          </div>
                        </div>
                        <h5 className="post-title">
                          <a href="blog-post-layout-one.html">
                            Data Secure on Transitioning to a New Office
                          </a>
                        </h5>
                      </div>
                      {/* <!-- Post info End --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--=========== Service Projects Wrapper End =============-->
        <!--============ Contact Us Area Start =================--> */}
          <div className="contact-us-area service-contact-bg section-space--ptb_100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <div className="contact-info sytle-one service-contact text-left">
                    <div className="contact-info-title-wrap text-center">
                      <h3 className="heading text-white mb-10">4.9/5.0</h3>
                      <div className="ht-star-rating lg-style">
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                      </div>
                      <p className="sub-text">
                        by 300+ customers for 600+ Projects
                      </p>
                    </div>

                    <div className="contact-list-item">
                      <a href="tel:190068668" className="single-contact-list">
                        <div className="content-wrap">
                          <div className="content">
                            <div className="icon">
                              <span className="fal fa-phone"></span>
                            </div>
                            <div className="main-content">
                              <h6 className="heading">Call for advice now!</h6>
                              <div className="text">+91-809-396-2212</div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        href="mailto:connect@coolhaxlabs.com"
                        className="single-contact-list"
                      >
                        <div className="content-wrap">
                          <div className="content">
                            <div className="icon">
                              <span className="fal fa-envelope"></span>
                            </div>
                            <div className="main-content">
                              <h6 className="heading">Say hello</h6>
                              <div className="text">
                                connect@coolhaxlabs.com
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 ml-auto">
                  <div className="contact-form-service-wrap">
                    <div className="contact-title text-center section-space--mb_40">
                      <h3 className="mb-10">Let's talk tech!</h3>
                      <p className="text">
                      Share your vision, and we'll craft a solution.
                      </p>
                    </div>

                    {/* <!-- <form className="contact-form" id="contact-form-2" action="https://whizthemes.com/mail-php/jowel/mitech/php/services-mail.php" method="post"> --> */}
                    <form
                      className="contact-form"
                      id="contact-form-2"
                      action="assets/php/services-mail.php"
                      method="post"
                    >
                      <div className="contact-form__two">
                        <div className="contact-input">
                          <div className="contact-inner">
                            <input
                              name="con_name"
                              type="text"
                              placeholder="Name *"
                            />
                          </div>
                          <div className="contact-inner">
                            <input
                              name="con_email"
                              type="email"
                              placeholder="Email *"
                            />
                          </div>
                        </div>
                        <div className="contact-select">
                          <div className="form-item contact-inner">
                            <span className="inquiry">
                              <select id="Visiting" name="Visiting">
                                <option disabled selected>
                                  Select Department to email
                                </option>
                                <option value="Your inquiry about">
                                  Your inquiry about
                                </option>
                                <option value="General Information Request">
                                  General Information Request
                                </option>
                                <option value="Partner Relations">
                                  Partner Relations
                                </option>
                                <option value="Careers">Careers</option>
                                <option value="Software Licencing">
                                  Software Licencing
                                </option>
                              </select>
                            </span>
                          </div>
                        </div>
                        <div className="contact-inner contact-message">
                          <textarea
                            name="con_message"
                            placeholder="Please describe what you need."
                          ></textarea>
                        </div>
                        <div className="comment-submit-btn">
                          <button className="ht-btn ht-btn-md" type="submit">
                            Send message
                          </button>
                          <p className="form-messege-2"></p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--============ Contact Us Area End =================-->
        <!--====================  brand logo slider area ====================--> */}
          {/* <div className="brand-logo-slider-area section-space--ptb_60">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="brand-logo-slider__container-area">
                    <div className="swiper-container brand-logo-slider__container">
                      <div className="swiper-wrapper brand-logo-slider__one">
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-01.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-01-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-02.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-02-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-03.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-03-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-04.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-04-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-05.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-05-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-06.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-06-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-07.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-07-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-08.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-08-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                        <div className="swiper-slide brand-logo brand-logo--slider">
                          <a href="#">
                            <div className="brand-logo__image">
                              <img
                                src="assets/images/brand/mitech-client-logo-09.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="brand-logo__image-hover">
                              <img
                                src="assets/images/brand/mitech-client-logo-09-hover.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <!--====================  End of brand logo slider area  ====================--> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
