import React from "react";
import {Link, NavLink } from "react-router-dom";

function Header() {

  const checkActive = (match, location) => {
    const {pathname} = location;
    if(pathname==="/our-history" || pathname==="/why-choose-us" || pathname==="/faq" || pathname==="/careers")
      return true;
    else return false;
  } 

  return (
    <>
      <div className="preloader-activate preloader-active open_tm_preloader">
        <div className="preloader-area-wrap">
          <div className="spinner d-flex justify-content-center align-items-center h-100">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>activeClassName="active"
        </div>
      </div>
      <div className="header-area">
        <div className="header-top-bar-info bg-gray d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="top-bar-wrap">
                  <div className="top-bar-left">
                    <div className="top-bar-text">
                      <NavLink
                        to="/careers"
                        activeClassName="active"
                        className="font-medium display-inline"
                      >
                        Now Hiring:
                      </NavLink>
                      Are you a driven and motivated software Engineer?
                    </div>
                  </div>
                  <div className="top-bar-right">
                    <ul className="top-bar-info">
                      <li className="info-item">
                        <a href="tel:918093962212" className="info-link">
                          <i className="info-icon fa fa-phone"></i>
                          <span className="info-text">
                            <strong>+91-809-396-2212</strong>
                          </span>
                        </a>
                      </li>
                      <li className="info-item">
                        <i className="info-icon fa fa-map-marker-alt"></i>
                        <span className="info-text">
                          Indira Nagar, Bangalore, KA
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-bottom-wrap header-sticky bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="header position-relative">
                  {/* <!-- brand logo --> */}
                  <div className="header__logo">
                    <NavLink exact to="/">
                      <img
                        src="assets/images/logo/logo-dark.png"
                        className="img-fluid"
                        alt=""
                      />
                    </NavLink>
                  </div>

                  <div className="header-right">
                    {/* <!-- navigation menu --> */}
                    <div className="header__navigation menu-style-three d-none d-xl-block">
                      <nav className="navigation-menu">
                        <ul>
                          <li>
                            <NavLink exact to="/" activeClassName="active">
                              <span>Home</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/about" activeClassName="active">
                              <span>About</span>
                            </NavLink>
                          </li>
                          <li activeClassName="active">
                            <NavLink to="/why-choose-us">
                              <span>Why choose us</span>
                            </NavLink>
                          </li>
                          <li className="has-children has-children--multilevel-submenu">
                            <Link>
                              <span>Solutions</span>
                              </Link>
                            <ul className="submenu">
                              <li>
                                <a href="it-services.html">
                                  <span>IT Services</span>
                                </a>
                              </li>
                              <li>
                                <a href="managed-it-service.html">
                                  <span>Managed IT Services</span>
                                </a>
                              </li>
                              <li>
                                <a href="industries.html">
                                  <span>Industries</span>
                                </a>
                              </li>
                              <li>
                                <a href="business-solution.html">
                                  <span>Business solution</span>
                                </a>
                              </li>
                              <li>
                                <a href="it-services-details.html">
                                  <span>IT Services Details</span>
                                </a>
                              </li>
                            </ul>
                          </li>
                          {/* <li>
                            <NavLink to="/case-studies">
                              <span>Case Studies</span>
                            </NavLink>
                          </li> */}
                          <li>
                            <NavLink to="/contact">
                              <span>Contact</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/blog">
                              <span>Blog</span>
                            </NavLink>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    {/* <!-- mobile menu --> */}
                    <div
                      className="mobile-navigation-icon d-block d-xl-none"
                      id="mobile-menu-trigger"
                    >
                      <i></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--====================  scroll top ====================--> */}
      <NavLink to="" className="scroll-top" id="scroll-top">
        <i className="arrow-top fal fa-long-arrow-up"></i>
        <i className="arrow-bottom fal fa-long-arrow-up"></i>
      </NavLink>
      {/* <!--====================  End of scroll top  ====================-->

    <!--====================  mobile menu overlay ====================--> */}
      <div className="mobile-menu-overlay" id="mobile-menu-overlay">
        <div className="mobile-menu-overlay__inner">
          <div className="mobile-menu-overlay__header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-6 col-8">
                  {/* <!-- logo --> */}
                  <div className="logo">
                    <NavLink to="/">
                      <img
                        src="assets/images/logo/logo-dark.png"
                        className="img-fluid"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-6 col-4">
                  {/* <!-- mobile menu content --> */}
                  <div className="mobile-menu-content text-right">
                    <span
                      className="mobile-navigation-close-icon"
                      id="mobile-menu-close-trigger"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-overlay__body">
            <nav className="offcanvas-navigation">
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About</NavLink>
                </li>
                <li className="has-children">
                  <NavLink to="">Company</NavLink>
                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/why-choose-us">
                        <span>Why choose us</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/our-history">
                        <span>Our history</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/faq">
                        <span>FAQs</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/careers">
                        <span>Careers</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="has-children">
                  <NavLink to="">IT solutions</NavLink>
                  <ul className="sub-menu">
                    <li>
                      <a href="it-services.html">
                        <span>IT Services</span>
                      </a>
                    </li>
                    <li>
                      <a href="managed-it-service.html">
                        <span>Managed IT Services</span>
                      </a>
                    </li>
                    <li>
                      <a href="industries.html">
                        <span>Industries</span>
                      </a>
                    </li>
                    <li>
                      <a href="business-solution.html">
                        <span>Business solution</span>
                      </a>
                    </li>
                    <li>
                      <a href="it-services-details.html">
                        <span>IT Services Details</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="/case-studies">Case Studies</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">
                    <span>Contact us</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/* <!--====================  End of mobile menu overlay  ====================--> */}
    </>
  );
}

export default Header;
