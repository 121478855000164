import React from "react";
import { Link, NavLink } from "react-router-dom";

const Blog = () => {
  return (
    <>
      {/* <!-- breadcrumb-area start --> */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h2 className="breadcrumb-title">Blog</h2>
                {/* <!-- breadcrumb-list start --> */}
                <ul className="breadcrumb-list">
                  <li className="breadcrumb-item">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">Blogs</li>
                </ul>
                {/* <!-- breadcrumb-list end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- breadcrumb-area end --> */}

      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          {/* <!--====================  Blog Area Start ====================--> */}
          <div className="blog-pages-wrapper section-space--ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  {/* <!--======= Single Blog Item Start ========--> */}
                  <div className="single-blog-item blog-grid">
                    {/* <!-- Post Feature Start --> */}
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img
                          className="img-fluid"
                          src="assets/images/blog/blog-01-370x230.jpg"
                          alt="Blog Imgs"
                        />
                      </a>
                    </div>
                    {/* <!-- Post Feature End -->
    
                                    <!-- Post info Start --> */}
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          January 14, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">Ideas for High Returns on Investment</a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>
                          Using the profit accumulator is one such way, and you
                          should understand why it increases your chances of the
                          profits. …
                        </p>
                      </div>
                      <div className="btn-text">
                        <a href="#">
                          Read more{" "}
                          <i className="ml-1 button-icon far fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Post info End --> */}
                  </div>
                  {/* <!--===== Single Blog Item End =========--> */}
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  {/* <!--======= Single Blog Item Start ========--> */}
                  <div className="single-blog-item blog-grid">
                    {/* <!-- Post Feature Start --> */}
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img
                          className="img-fluid"
                          src="assets/images/blog/blog-08-370x230.jpg"
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    {/* <!-- Post Feature End --> */}

                    {/* <!-- Post info Start --> */}
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">
                          How Technology Made Businesses More Efficient
                        </a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>
                          They play a role in making operations more seamless,
                          bridging the gap between authorities, consumers and
                          businesses. …
                        </p>
                      </div>
                      <div className="btn-text">
                        <a href="#">
                          Read more{" "}
                          <i className="ml-1 button-icon far fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Post info End --> */}
                  </div>
                  {/* <!--===== Single Blog Item End =========--> */}
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  {/* <!--======= Single Blog Item Start ========--> */}
                  <div className="single-blog-item blog-grid">
                    {/* <!-- Post Feature Start --> */}
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img
                          className="img-fluid"
                          src="assets/images/blog/blog-07-370x230.jpg"
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    {/* <!-- Post Feature End -->
    
                                    <!-- Post info Start --> */}
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">
                          Data Secure on Transitioning to a New Office
                        </a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>
                          It is far wiser to do your own installations and
                          ensure that all data flowing through the organization
                          has end-to-end encryption. …
                        </p>
                      </div>
                      <div className="btn-text">
                        <a href="#">
                          Read more{" "}
                          <i className="ml-1 button-icon far fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Post info End --> */}
                  </div>
                  {/* <!--===== Single Blog Item End =========--> */}
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  {/* <!--======= Single Blog Item Start ========--> */}
                  <div className="single-blog-item blog-grid">
                    {/* <!-- Post Feature Start --> */}
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img
                          className="img-fluid"
                          src="assets/images/blog/blog-07-370x230.jpg"
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    {/* <!-- Post Feature End --> */}

                    {/* <!-- Post info Start --> */}
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">
                          The Keys to Persuading Customers Nowadays
                        </a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>
                          It is far wiser to do your own installations and
                          ensure that all data flowing through the organization
                          has end-to-end encryption. …
                        </p>
                      </div>
                      <div className="btn-text">
                        <a href="#">
                          Read more{" "}
                          <i className="ml-1 button-icon far fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Post info End --> */}
                  </div>
                  {/* <!--===== Single Blog Item End =========--> */}
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  {/* <!--======= Single Blog Item Start ========--> */}
                  <div className="single-blog-item blog-grid">
                    {/* <!-- Post Feature Start --> */}
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img
                          className="img-fluid"
                          src="assets/images/blog/blog-05-370x230.jpg"
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    {/* <!-- Post Feature End --> */}

                    {/* <!-- Post info Start --> */}
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <Link to="/blog-post">
                          Blockchain Technology Effects on Logistics Sector?
                        </Link>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>
                          Changing or adding information to the “chain” requires
                          the authentication of everyone who is in the network.
                          …
                        </p>
                      </div>
                      <div className="btn-text">
                        <Link to="/blog-post">
                          Read more
                          <i className="ml-1 button-icon far fa-long-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                    {/* <!-- Post info End --> */}
                  </div>
                  {/* <!--===== Single Blog Item End =========--> */}
                </div>

                <div className="col-lg-4 col-md-6  mb-30 wow move-up">
                  {/* <!--======= Single Blog Item Start ========--> */}
                  <div className="single-blog-item blog-grid">
                    {/* <!-- Post Feature Start --> */}
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img
                          className="img-fluid"
                          src="assets/images/blog/blog-02-370x230.jpg"
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    {/* <!-- Post Feature End -->
    
                                    <!-- Post info Start --> */}
                    <div className="post-info lg-blog-post-info">
                      <div className="post-meta">
                        <div className="post-date">
                          <span className="far fa-calendar meta-icon"></span>
                          February 28, 2019
                        </div>
                      </div>

                      <h5 className="post-title font-weight--bold">
                        <a href="#">
                          How Wireless Technology is Changing Business
                        </a>
                      </h5>

                      <div className="post-excerpt mt-15">
                        <p>
                          If there’s one way that wireless technology has
                          changed the way we work, it’s that everyone is now
                          connected. …
                        </p>
                      </div>
                      <div className="btn-text">
                        <a href="#">
                          Read more{" "}
                          <i className="ml-1 button-icon far fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    {/* <!-- Post info End --> */}
                  </div>
                  {/* <!--===== Single Blog Item End =========--> */}
                </div>

                <div className="col-lg-12 wow move-up">
                  <div className="ht-pagination mt-30 pagination justify-content-center">
                    <div className="pagination-wrapper">
                      <ul className="page-pagination">
                        <li>
                          <a className="prev page-numbers" href="#">
                            Prev
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers current" href="#">
                            1
                          </a>
                        </li>
                        <li>
                          <a className="page-numbers" href="#">
                            2
                          </a>
                        </li>
                        <li>
                          <a className="next page-numbers" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--====================  Blog Area End  ====================--> */}

          {/* <!--========== Call to Action Area Start ============--> */}
          <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-8 col-lg-7">
                  <div className="cta-content md-text-center">
                    <h3 className="heading text-white">
                      Assess your business potentials and find opportunities{" "}
                      <span className="text-color-secondary">
                        for bigger success
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5">
                  <div className="cta-button-group--one text-center">
                    <a href="#" className="btn btn--white btn-one">
                      <span className="btn-icon mr-2">
                        <i className="far fa-comment-alt-dots"></i>
                      </span>{" "}
                      Let's talk
                    </a>
                    <a href="#" className="btn btn--secondary  btn-two">
                      <span className="btn-icon mr-2">
                        <i className="far fa-info-circle"></i>
                      </span>{" "}
                      Get info
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--========== Call to Action Area End ============--> */}
        </div>
      </div>
    </>
  );
};

export default Blog;
