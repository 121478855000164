import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import About from "./pages/About";
import Contact from "./pages/Contact";
import WhyChooseUs from "./pages/WhyChooseUs";
import OurHistory from "./pages/OurHistory";
import Faq from "./pages/Faq";
import Careers from "./pages/Careers";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import ScrollToTop from "./components/ScrollToTop";
import CaseStudies from "./pages/CaseStudies";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-GF60NXF3ZS');


function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/why-choose-us" component={WhyChooseUs} />
          <Route path="/our-history" component={OurHistory} />
          <Route path="/faq" component={Faq} />
          <Route path="/careers" component={Careers} />
          <Route path="/blog" component={Blog} />
          <Route path="/blog-post" component={BlogPost} />
          <Route path="/case-studies" component={CaseStudies} />
          <Route path="/case-study-details" component={CaseStudyDetails} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
